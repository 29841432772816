<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Repair History
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Repairs',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Repairs',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your repair logs</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View repair logs of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all repair logs</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            auto-select-first
            filled
            multiple
            flat
            v-model="vehicleUuids"
            :items="vehicles"
            item-text="text"
            item-value="uuid"
            label="Vehicles"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ vehicleUuids.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            auto-select-first
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Staff"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ staffUuids.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-btn depressed block color="green" dark @click="exportCSV">
            <v-icon class="me-2"> mdi-file-export-outline </v-icon> export csv
          </v-btn>
        </v-col>
      </v-card-title>
      <v-data-table
        id="imso-log-table"
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>

        <!-- <template
          v-slot:item.actions="{ item }"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Repairs',
              'Delete'
            )
          "
        >
          <v-btn
            depressed
            small
            icon
            color="error"
            class="mx-0 px-0 mt-1"
            dark
            @click="
              selectedItem = item;
              deleteDialog = true;
            "
          >
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>

    <!-- Delete dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
      v-if="selectedItem"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this vehicle repair record? This
          action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteVehicleRepairX(selectedItem)"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { deleteVehicleRepair } from "@/services/vehicleRepairsService";

import { fetchAllVehicleRepairs } from "@/services/logsService";

import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
} from "@/services/preloadDataService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    vehicleUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 2,
    headers: [
      {
        text: "Date",
        align: "start",
        value: "date",
        width: 105,
        sortable: false,
      },
      { text: "Vehicle No", value: "vehicles.vehicle_no", sortable: false },
      { text: "Driver", value: "staff.first_name", sortable: false },
      { text: "Meter Box", value: "meter_box_id", sortable: false },
      {
        text: "Meter Reading (km)",
        value: "meter_reading_km",
        sortable: false,
      },
      {
        text: "Amount (LKR)",
        value: "amount",
        sortable: false,
      },
      { text: "Description", value: "description", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    vehicleUuids: [],
    staffUuids: [],
    dataLoading: false,
    loading: false,
    deleteDialog: false,
    deleteLoading: false,
    selectedItem: null,
    vehicles: [],
    staff: [],
  }),
  async created() {
    this.dataLoading = true;
    this.vehicles = await fetchAndStoreVehiclesData();
    this.vehicles.forEach((element) => {
      if (element.category)
        element.text = `${element.vehicle_no} [${element.category}]`;
      else element.text = element.vehicle_no;
    });
    this.staff = await fetchAndStoreStaffData();
    this.staff.forEach((person) => {
      person["name"] = person.first_name + " " + person.last_name;
    });
    await this.fetchLogs();
    this.dataLoading = false;
  },
  mounted() {
    //
  },
  methods: {
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyVehicleRepairList",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubVehicleRepairList",
        });
      }
    },
    async fetchLogs() {
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle Repairs",
          "Read All"
        )
      ) {
        this.loading = true;
        this.items = await fetchAllVehicleRepairs(
          this.page,
          this.limit,
          this.startDate ? this.startDate : "",
          this.endDate ? this.endDate : "",
          JSON.stringify(this.vehicleUuids),
          JSON.stringify(this.staffUuids),
          JSON.stringify(this.projectUuids)
        );
        this.loading = false;
      } else {
        this.$router.push({
          name: "PageDashboard",
        });
      }
    },
    async deleteVehicleRepairX(item) {
      this.deleteLoading = true;
      let deleteRepairReturn = await deleteVehicleRepair(item.vehicle_uuid, {
        uuid: item.uuid,
      });
      this.deleteLoading = false;

      if (deleteRepairReturn == "success") {
        this.deleteDialog = false;
        await this.fetchLogs();
      }
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Vehicle Repairs Log";

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
  },
};
</script>